export default defineI18nConfig(() => ({
  fallbackLocale: 'en',
  fallbackRoot: true,
  silentFallbackWarn: true,
  warnHtmlMessage: false,
  warnHtmlInMessage: false,
  globalInjection: true,
  dateTimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    },
    ar: {
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    },
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 0,
      },
      plain: {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits: 0,
      },
    },
    ar: {
      currency: {
        style: 'currency',
        currency: 'EGP',
        minimumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 0,
      },
      plain: {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits: 0,
      },
    },
  },
}));
